<!--余额账户明细-->
<template>
  <div class="balance">
    <!-- 查詢 -->
    <el-row :gutter="10" style="margin-bottom: 20px;">

      <el-col :span="8">
        <SelecteMS @reload="update"></SelecteMS>
      </el-col>
      <el-col :span="6"><el-date-picker v-model="dateToDate" type="daterange" align="center" unlink-panels
          range-separator="~" start-placeholder="交易时间" end-placeholder="交易时间" :picker-options="pickerOptions"
          @change="reload" clearable value-format="yyyy-MM-dd" class="">
        </el-date-picker></el-col>
      <el-col :span="4">
        <el-select v-model="tableParams.businessTypeSet" placeholder="明细分类" clearable @change="reload" style="margin-left: 20px;"  multiple collapse-tags>
          <el-option v-for="item in ObjToOpt(businessStatusObj)" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-col>
    </el-row>

    <!-- 表格 -->
    <el-table :data="tableData" border stripe :header-cell-style="{
      background: '#FAFAFA',
      color: '#000000',
    }">
      <el-table-column label="交易时间" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.addDt }}</span>
        </template>
      </el-table-column>
      <el-table-column label="交易门店" align="center" prop="shopName">

      </el-table-column>
      <el-table-column label="明细分类" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.businessTypeName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="余额类型" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.accountType == 1 ? "本金" : "赠金" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="交易金额" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.amount }}</span>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <section class="table-footer">
      <el-pagination layout="total,sizes, prev, pager, next,jumper" background :total="total"
        :page-size="tableParams.size" @size-change="onSizeChange" :page-sizes="[10, 20, 50, 100]"
        :current-page.sync="currentPage" @current-change="onCurrentPage">
      </el-pagination>
    </section>
  </div>
</template>
<script>
import { ObjToOpt } from "@/utils/utils";
import { pickerOptions,businessStatusObj } from "@/db/objs";
import { getUserBalanceInfo } from "@/api/user/user.js";
import SelecteMS from '.././components/selecteMS'
export default {
  components: {
    SelecteMS
  },
  data() {
    return {
      ObjToOpt,
      pickerOptions,
      businessStatusObj,
      dateToDate: [], //日期
      tableParams: {
        page: 1,
        size: 10,
        merchantId: "",
        shopId: "",
        memberId: "",
        searchStartTime: "",
        searchEndTime: "",
        accountTypeSet: [1, 2]
      },
      tableData: [],
      total: 0,
      currentPage: 1,

    };
  },
  created() {
    this.tableParams.memberId = this.$route.query.memberId;
  },
  mounted() {
    this.getUserBalanceInfo();

  },
  methods: {
    // 用户账户明细
    getUserBalanceInfo() {
      if (this.dateToDate) {
        this.tableParams.searchStartTime = this.dateToDate[0];
        this.tableParams.searchEndTime = this.dateToDate[1];
      } else {
        this.tableParams.searchStartTime = "";
        this.tableParams.searchEndTime = "";
      }
      getUserBalanceInfo(this.tableParams).then((res) => {
        if (res.isSuccess == "yes") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    // 【监听】表格重载
    reload() {
      this.tableParams.page = 1;
      this.currentPage = 1;
      this.getUserBalanceInfo();
    },
    // 【监听】表格分页点击
    onCurrentPage(page) {
      this.tableParams.page = page;
      this.getUserBalanceInfo();
    },

    // 【监听】表格条数点击
    onSizeChange(size) {
      this.tableParams.size = size;
      this.reload();
    },

    update(e) {
      this.tableParams.merchantId = e.merchantId;
      this.tableParams.shopId = e.shopId;
      this.reload()
    },
  },
};
</script>
<style lang="scss" scoped>
.balance {
  padding: 0.15rem;
  box-sizing: border-box;
}

.table-search {
  display: flex;
  margin-bottom: 0.15rem;
}

.search_L {
  display: flex;
}

.table-footer {
  margin-top: 0.1rem;
  display: flex;
  justify-content: flex-end;
}

.form-footer {
  display: flex;
  justify-content: flex-end;
}
</style>
